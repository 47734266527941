/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@font-face {
  font-family: "SFProBold";
  src: local("SFProBold"), url(./assets/fonts/sfprotext/FontsFree-Net-SFProText-Bold-1.ttf) format("truetype");
}

html, body{
  font-family: Roboto;
  padding: 0;
  margin: 0;
  overflow: hidden;
  height: 100%;
}
.storefinder-button {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  margin: 0 auto;
  display: block;
  font-size: 17px;
  font-family: Roboto;
  height: 56px;
  border: none;
  letter-spacing: 0.37px;
}

.storefinder-black-button {
  background-color: #262626;
  color: #fff;
}

.storefinder-white-button {
  background-color: white;
  color: black;
  border: 1px solid black;
}

.pos-dialog-container {
  mat-dialog-container {
      padding: 0 !important;
  }
}

.mat-expansion-panel-body {
   padding:0 !important;
   width: 200px;
}
.pos-category-desktop {
  ng::deep .mat-chip-list-wrapper {
    margin-left: -15px !important;
  }
}

.gm-style-cc {
  display: none;
}

img[alt="Google"] {
  display: none !important;
}